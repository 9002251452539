import Vue from "vue";

/*TITLE*/
document.title = "Edificio Las Terrazas | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Edificio Las Terrazas";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Edificio Las Terrazas";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "las-terrazas-salon-forest-colonial-mascara-cmyk-copia--20230113080132.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "las-terrazas-banyo-principal-forest-colonial-newport-beige-old-beige-vista-2--20230113080145.jpg";
Vue.prototype.$image_bath2 = "las-terrazas-banyo-cortesia-forest-colonial-newport-beige-old-beige--20230113080107.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "las-terrazas-salon-forest-colonial-mascara-cmyk-copia--20230113080132.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "las-terrazas-banyo-principal-forest-colonial-newport-beige-old-beige-vista-2--20230113080145.jpg",
  },
  {
    src: "las-terrazas-banyo-principal-forest-colonial-newport-beige-old-beige-vista-1-copia--20230113080155.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "las-terrazas-banyo-cortesia-forest-colonial-newport-beige-old-beige--20230113080107.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=santa%20cruz%20de%20tenerife%2038687",
    text: "Santa Cruz de Tenerife, 38687",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=santa%20cruz%20de%20tenerife%2038687",
    text: "Santa Cruz de Tenerife, 38687",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:922179818/620670667",
    text: "922179818/620670667",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@grupomedano.com",
    text: "info@grupomedano.com",
  },
];
